/* eslint-disable */
import Swiper from 'swiper/bundle'
import Cookieconsent from 'vanilla-cookieconsent'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import mapboxgl from 'mapbox-gl'
import jssocials from 'jssocials';
import Cookies from 'js-cookie';
import jQB from 'jquery-bridget';
import Isotope from 'isotope-layout';

export default {
  init() {
    // JavaScript to be fired on all pages
    jQB('isotope', Isotope, $);
    let scroll = $(window).scrollTop();
    let windowWidth = $(window).width();
    let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
    mapboxgl.accessToken = 'pk.eyJ1IjoiYmNuY28iLCJhIjoiY2wxeHEyNm5xMDBvOTNjcmE4bHRzZHJyeiJ9.d4ATsz8VW2qpcDG-Ic9Sbg';

    $(function () {

      // $(window).scroll(function(e){
      //   var $el = $('.event-resume');
      //   var isPositionFixed = ($el.css('position') == 'fixed');
      //   if ($(this).scrollTop() > 200 && !isPositionFixed){
      //     $el.css({'position': 'fixed', 'top': '0px'});
      //   }
      //   if ($(this).scrollTop() < 200 && isPositionFixed){
      //     $el.css({'position': 'static', 'top': '0px'});
      //   }
      // });

      if ($('#addToCal').length > 0) {
        new atc(document.querySelector('#addToCal'), {
          labels: false,
          timezone: "Europe/Paris",
        })
      }

      $(window).scroll(function () {
        scroll = $(window).scrollTop();
        posHeader();
      });

      $(window).resize(function () {
        windowWidth = $(window).width();
      });


      // Blur on click
      $('a, button, input[type=\'submit\'], .btn').on('click', function () {
        $(this).blur();
      });


      // Button scroll down
      $('.js_btn-scroll-down').on('click', function () {
        $('html,body').stop().animate({
          scrollTop: $(this).closest('.module').offset().top + $(this).closest('.module').outerHeight(true),
        }, 1000);
      });


      // ========================================
      // Header
      // ========================================
      $('.js_toggle-menu').on('click', function () {
        if (windowWidth <= 1024) {
          $(this).toggleClass('close');
          $('html').toggleClass('disable-scroll');
          $('body').toggleClass('menu-active');
        }
      });

      $('#header .menu-primary .menu > li.menu-item-has-children > a').on('click', function (e) {
        if (windowWidth <= 1024) {
          e.preventDefault();
          let $elt = $(this);
          $elt.closest('li').siblings('li').find('a').removeClass('active');
          $elt.closest('li').siblings('li').find('.sub-menu').slideUp(400);
          $elt.toggleClass('active').next('.sub-menu').slideToggle(400);
        }
      });

      $('.js_copy-link').on('click', function () {
        let URL = document.location.href;
        navigator.clipboard.writeText(URL);
        if (windowWidth > 1024) {
            $(this).toggleClass('copied')
        }
      });

      $('.js_btn-toggle-search').on('click', function () {
        $('body').toggleClass('search-active');
        $('html').toggleClass('disable-scroll');
        setTimeout(function () {
          if ($('body').hasClass('search-active')) {
            $('.search-form .search-field').focus();
          }
        }, 100);
      });

      let position = $(window).scrollTop();
      let keepHeaderVisible = false;
      let headerSticky = 0;
      let $header = $('#header');
      let headerHeight;
      let headerTop;
      let $headerContent = jQuery('.header-page-content');

      function posHeader() {
        headerHeight = $header.outerHeight(true);
        headerTop = $('#header').offset().top;

        if (scroll <= headerTop) {
          $('body').removeClass('scroll-up scroll-down');
        } else if (scroll > headerTop) {
          $('body').addClass('scroll-down').removeClass('scroll-up');
        } else {
          $('body').addClass('scroll-up').removeClass('scroll-down');
        }

        if ($(document).width() > 1) {
          if (scroll > position) {
            // Scroll down
            if (scroll > headerHeight + headerTop) {
              // Cache le header
              headerSticky = headerHeight + headerTop;
              $header.addClass('transition');
            } else {
              if (!keepHeaderVisible) {
                // Cache le header progressivement suivant le scroll
                headerSticky = scroll;
                $header.removeClass('transition');
              }
            }
          } else {
            // Scroll Up
            if (scroll > headerHeight + headerTop) {
              // Affiche le header
              headerSticky = headerTop;
              $header.addClass('transition');
              keepHeaderVisible = true; // Garde le header visible jusqu'en haut de page
            } else if (keepHeaderVisible && scroll > headerTop) {
              headerSticky = headerTop;
              $header.addClass('transition');
            } else {
              // Affiche le header progressivement suivant le scroll
              headerSticky = scroll;
              $header.removeClass('transition');
              keepHeaderVisible = false; // Garde le header visible jusqu'en haut de page
            }
          }
          if (scroll <= 0) {
            headerSticky = 0;
            keepHeaderVisible = false;
          }

          $('#header .header-wrapper').css('transform', 'translateY(-' + headerSticky + 'px)');

          position = scroll;
        } else {
          $('#header .header-wrapper').css('transform', '');
        }
      }

      posHeader();


      // ========================================
      // Footer
      // ========================================
      $('.js_footer-btn-scroll').on('click', function () {
        $('html,body').stop().animate({
          scrollTop: 0,
        }, 1500);
      });


      // ========================================
      // Modules
      // ========================================
      // Module Hero
      if ($('.module-hero .item').length > 1) {
        new Swiper('.js_slider-hero', {
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 4000,
            disableOnInteraction: true,
          },
          navigation: {
            nextEl: '.js_slider-hero-btn-next',
            prevEl: '.js_slider-hero-btn-prev',
          },
        });
      }


      // Module News slider
      $('.js_news-slider').each(function (index) {
        let elt = this;
        $(elt).siblings('.js_news-slider-btn-prev, .js_news-slider-btn-next').addClass('index-' + index);

        let params = {
          loop: true,
          speed: 750,
          navigation: {
            nextEl: '.js_news-slider-btn-next.index-' + index,
            prevEl: '.js_news-slider-btn-prev.index-' + index,
          },
        };

        if ($(elt).hasClass('columns-2')) {
          params.spaceBetween = 26;
          params.slidesPerView = 1;
          params.breakpoints = {
            601: {
              slidesPerView: 2,
            },
          };
        } else if ($(elt).hasClass('columns-3')) {
          params.spaceBetween = 26;
          params.slidesPerView = 1;
          params.breakpoints = {
            601: {
              slidesPerView: 2,
            },
            1025: {
              slidesPerView: 3,
            },
          };
        } else {
          params.spaceBetween = 26;
          params.slidesPerView = 'auto';
        }

        new Swiper(elt, params);

      });


      // Module Logos slider
      $('.js_logos-slider').each(function (index) {
        let elt = this;
        $(elt).siblings('.js_logos-slider-btn-prev, .js_logos-slider-btn-next').addClass('index-' + index);
        let params = {
          // loop: true,
          speed: 750,
          slidesPerView: 2,
          spaceBetween: 20,
          autoplay: {
            delay: 3000,
            disableOnInteraction: true,
          },
          navigation: {
            nextEl: '.js_logos-slider-btn-next.index-' + index,
            prevEl: '.js_logos-slider-btn-prev.index-' + index,
          },
          breakpoints: {
            481: {
              slidesPerView: 3,
            },
            601: {
              slidesPerView: 4,
            },
            961: {
              slidesPerView: 5,
              spaceBetween: 26,
            },
            1025: {
              slidesPerView: 6,
            },
          },
        }
        if (windowWidth > 1024) {
          if ($(elt).find('.item').length > 6) {
            new Swiper(elt, params);
          }
        } else if (windowWidth > 960) {
          if ($(elt).find('.item').length > 5) {
            new Swiper(elt, params);
          }
        } else if (windowWidth > 600) {
          if ($(elt).find('.item').length > 4) {
            new Swiper(elt, params);
          }
        } else if (windowWidth > 480) {
          if ($(elt).find('.item').length > 3) {
            new Swiper(elt, params);
          }
        } else {
          if ($(elt).find('.item').length > 2) {
            new Swiper(elt, params);
          }
        }
      });


      // Module Accordion
      $('.js_toggle-accordion').on('click', function () {
        let $elt = $(this);
        let speed = 400;
        if ($elt.hasClass('active')) {
          $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
        } else {
          $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
          $elt.addClass('active').next('.txt').slideDown(speed);
        }
      });


      // Module Images slider
      let sliderImage = [];
      $('.js_images-slider').each(function (index) {
        let elt = this;
        if ($(elt).find('.item').length > 1) {
          $(elt).data('index', index); // Utilisé pour la popin du module gallery
          $(elt).siblings('.js_images-slider-btn-prev, .js_images-slider-btn-next, .js_images-slider-pagination').addClass('index-' + index);

          sliderImage[index] = new Swiper(elt, {
            loop: true,
            speed: 750,
            navigation: {
              nextEl: '.js_images-slider-btn-next.index-' + index,
              prevEl: '.js_images-slider-btn-prev.index-' + index,
            },
            pagination: {
              el: '.js_images-slider-pagination.index-' + index,
              type: 'bullets',
              clickable: true,
            },
          });
        }
      });


      // Module Video
      $('.js_btn-video').on('click', function () {
        let $elt = $(this);
        let $video = $elt.closest('.video');
        let $iframe = $video.find('iframe');
        let src = 'https://www.youtube-nocookie.com/embed/' + $elt.data('src') + '?rel=0&showinfo=0&enablejsapi=1&autoplay=1';
        $iframe.attr('src', src);
        $video.addClass('active');
        setTimeout(function () {
          $iframe[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        }, 600);
      });


      // Module References
      $('.js_references-slider').each(function () {
        let elt = this;
        if ($(elt).find('.sub-item').length > 1) {
          new Swiper(elt, {
            loop: true,
            speed: 750,
            allowTouchMove: false,
            navigation: {
              nextEl: '.js_references-slider-btn-next',
              prevEl: '.js_references-slider-btn-prev',
            },
            pagination: {
              el: '.js_references-slider-pagination',
              type: 'bullets',
              clickable: true,
            },
          });
        }
      });
      $('.js_references-popin-slider').each(function () {
        let elt = this;
        if ($(elt).find('.item').length > 1) {
          new Swiper(elt, {
            loop: true,
            speed: 750,
            navigation: {
              nextEl: '.js_references-popin-slider-btn-next',
              prevEl: '.js_references-popin-slider-btn-prev',
            },
            pagination: {
              el: '.js_references-popin-slider-pagination',
              type: 'bullets',
              clickable: true,
            },
          });
        }
      });

      // Event Map Toogle
      let mapToggle = document.getElementById("map-toggle");
      let mapModule = document.querySelector(".module-map")
      $(document).on('click', '#map-toggle', function () {
        mapToggle.classList.toggle('hide-map');
        mapModule.classList.toggle('hide-map');
      });

      // Module Map
      $('.js_load-map').each(function () {
        let elt = this;
        let markers = $(elt).attr('data-markers');
        const map = new mapboxgl.Map({
          container: $(elt).attr('id'),
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [$(elt).attr('data-lng'), $(elt).attr('data-lat')],
          zoom: 16,
        });
        // map.scrollZoom.disable();

        var coor = jQuery.parseJSON(markers);
        var bounds = new mapboxgl.LngLatBounds();

        coor.forEach(function (key) {
          const el = document.createElement('div');
          const containerLogo = document.createElement('div')
          containerLogo.setAttribute('class', 'container-pin');

          const logo = document.createElement("IMG");
          logo.setAttribute('class', 'img-pin');
          logo.src = $(elt).attr('data-pin');

          el.className = 'marker';
          containerLogo.append(logo);
          el.append(containerLogo);
          let newEl = new mapboxgl.Marker(el)
            .setLngLat([key[0], key[1]])
            .addTo(map);

          console.log("newEl",newEl);

          bounds.extend(key);
        });

        // permet d'ajuster le zoom en fonction des markers
        map.fitBounds(bounds, {padding: 25});
        var mapLat = map.getBounds().getCenter().lat;
        var mapLong = map.getBounds().getCenter().long;
        map.setCenter = (mapLat, mapLong);
        if (coor.length == 1) {
          var zoomAjust = 1;
          var mapZoom = map.getZoom();
          var newZoom = mapZoom - zoomAjust;
          map.setZoom(newZoom);
        }
      });


      // Module Text video slider
      let sliderTextVideo = [];
      $('.js_text-video-slider').each(function (index) {
        let elt = this;
        if ($(elt).find('.item').length > 1) {
          $(elt).siblings('.js_text-video-slider-btn-prev, .js_text-video-slider-btn-next').addClass('index-' + index);
          $(elt).find('.js_text-video-slider-btn-prev, .js_text-video-slider-btn-next').addClass('index-' + index);
          sliderTextVideo[index] = new Swiper(elt, {
            loop: true,
            speed: 750,
            navigation: {
              nextEl: '.js_text-video-slider-btn-next.index-' + index,
              prevEl: '.js_text-video-slider-btn-prev.index-' + index,
            },
          });
        }
      });


      // Module Share
      var url = "http://google.com";
      var text = "Some text to share";

      $('#share').jsSocials({
        showLabel: false,
        showCount: false,
        shares: ["email", "twitter", "facebook", "linkedin", "pinterest", "whatsapp", "messenger"]
      });


      // ========================================
      // Popin
      // ========================================
      function openPopin(popin, slide) {
        $('.popin-wrapper[data-popin="' + popin + '"]').show(0).addClass('active');
        $('html').addClass('disable-scroll');
        if (slide) {
          sliderImage[$('.popin-wrapper[data-popin="' + popin + '"]').find('.slider').data('index')].slideTo(slide);
        }
      }

      function closePopin(popin) {
        if (popin) {
          $('.popin-wrapper[data-popin="' + popin + '"]').removeClass('active');
          setTimeout(function () {
            $('html').removeClass('disable-scroll');
            $('.popin-wrapper[data-popin="' + popin + '"]').hide(0);
          }, 600);
        } else {
          $('.popin-wrapper').removeClass('active');
          setTimeout(function () {
            $('html').removeClass('disable-scroll');
            $('.popin-wrapper').hide(0);
          }, 600);
        }
      }

      $(document).on('click', '.js_open-popin', function () {
        openPopin($(this).data('popin'), $(this).data('slide'));
      });


      $(document).on('click', '.js_close-popin', function () {
        closePopin();
      });
      $(document).on('click', '.popin-wrapper', function (e) {
        if (e.target !== this) {
          return;
        }
        closePopin();
      });


      // ========================================
      // Popin Alert
      // ========================================
      $(document).on('click', '.js_close-popin-alert', function () {
        sessionStorage['alerte'] = 'alertClose';
      });

      $(document).on('click', '.popin-alert-wrapper', function (e) {
        if (e.target !== this) {
          return;
        }
        sessionStorage['alerte'] = 'alertClose';
      });
      let alerte = $('.popin-alert-wrapper');
      let data = sessionStorage['alerte'];
      if (data === 'alertClose') {
        closePopin('alert');
      } else if (alerte.length > 0) {
        openPopin('alert');
      }


     // ========================================
// Animations
// ========================================
gsap.registerPlugin(ScrollTrigger);

// Main
gsap.to('#main', { duration: 0.7, opacity: 1 });

// Vérifie si ce n'est pas WooCommerce
if (!$('body').hasClass('woocommerce')) {

  // Fonction pour optimiser les sélections
  function animateModule(selector, animations) {
    $(selector).each(function (_, elt) {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: elt,
          start: 'top 80%'
        }
      });
      animations(tl, $(elt));
    });
  }

  // Parallax sur les backgrounds
  $('.module.has-background-image.parallax .background').each(function (_, elt) {
    gsap.to(elt, {
      yPercent: 25,
      ease: "none",
      scrollTrigger: {
        trigger: elt,
        scrub: true
      }
    });
  });

  // Module Hero - List
  animateModule('.module-hero.mode-list', (tl, elt) => {
    tl.from(elt.find('.item:nth-child(1)'), { x: -75, opacity: 0, duration: 0.7 })
      .from(elt.find('.item:nth-child(2)'), { x: 75, opacity: 0, duration: 0.7 }, '-=0.5');
  });

  // Module Posts list
  animateModule('.module-posts-list', (tl, elt) => {
    tl.from(elt.find('.title-module'), { y: 25, opacity: 0, duration: 0.7 })
      .from(elt.find('.item'), { y: 75, opacity: 0, stagger: 0.1, duration: 0.7 }, '-=0.5');
  });

  // Modules avec un simple fade-in depuis le bas
  const modulesToAnimate = [
    '.module-news-slider', '.module-text', '.module-head-text', '.module-logos-slider',
    '.module-contact', '.module-form', '.module-map', '.module-accordion',
    '.module-images-slider', '.module-video', '.module-quote', '.module-references',
    '.module-gallery', '.module-icons', '.module-key-figures', '.module-link',
    '.module-text-video-slider', '.module-clickable .items'
  ];

  modulesToAnimate.forEach(selector => {
    animateModule(selector, (tl, elt) => {
      tl.from(elt.find('.title-module'), { y: 25, opacity: 0, duration: 0.7 })
        // .from(elt.find('.slider-wrapper, .txt, .btn-wrapper, .tabs, .list .item'), {
        .from(elt.find(' .txt, .btn-wrapper, .tabs, .list .item'), {
          y: 75,
          opacity: 0,
          stagger: 0.1,
          duration: 0.7
        }, '-=0.5');
    });
  });

    // Module Text image
$('.module-text-image').each(function (index, elt) {
  let tlTextImage = gsap.timeline({
    scrollTrigger: {
      trigger: elt,
      start: 'top 80%',
    },
  });

  tlTextImage
    .from($(elt).find('.img-left .col-1'), { duration: 0.7, x: -75, opacity: 0 })
    .from($(elt).find('.img-left .col-2'), { duration: 0.7, x: 75, opacity: 0 }, '-=0.5')
    .from($(elt).find('.img-right .col-1'), { duration: 0.7, x: 75, opacity: 0 }, '-=0.5')
    .from($(elt).find('.img-right .col-2'), { duration: 0.7, x: -75, opacity: 0 }, '-=0.5');
});

  // Page Archive
  $('.page-archive-news, .page-archive-references').each(function (_, elt) {
    gsap.from($(elt).find('.title-page-wrapper, .tabs, .list-single .item'), {
      scrollTrigger: {
        trigger: elt,
        start: 'top 80%'
      },
      y: 75,
      opacity: 0,
      stagger: 0.1,
      duration: 0.7
    });
  });
}

// Loader
if ($('.loader').length > 0) {
  gsap.to('.loader img', { duration: 1.1, y: -75, ease: 'power1.inOut', delay: 1.5 });
  gsap.to('.loader', { duration: 0.9, opacity: 0, delay: 1.8 });
  gsap.to('.loader', { duration: 0, className: 'loader disable', delay: 2.8 });
}



      // Event Toogle
      let toggleButton = document.getElementById("eventToggleButton");
      let main = document.querySelector('main');
      if (toggleButton) {
        toggleButton.addEventListener("click", function () {
          if (jQuery('main').hasClass('event-style-list')) {
            jQuery(main).removeClass('event-style-list');
            main.classList.add('event-style-grid');
          } else if (jQuery('main').hasClass('event-style-grid')) {
            jQuery(main).removeClass('event-style-grid');
            main.classList.add('event-style-list');
          }
        });
      }


      // ========================================
      // Cookie
      // ========================================
      let cc = initCookieConsent();
      const setMapCookie = function (cookie) {
        let cooks = Cookies.get();
        if (cookie.categories && cookie.categories.includes('advertising')) {
          if (!cooks.map) {
            Cookies.set('map', 1, {expires: 182, path: '/'});
          }
        } else {
          if (cooks.map) {
            Cookies.remove('map', {path: '/'});
          }
        }
        return true;
      }

      // run plugin with your configuration
      cc.run({
        current_lang: 'fr',

        gui_options: {
          consent_modal: {
            layout: 'box',              // box/cloud/bar
            position: 'bottom left',    // bottom/middle/top + left/right/center
            transition: 'slide',        // zoom/slide
          },
          settings_modal: {
            layout: 'box',              // box/bar
            // position : 'left',       // left/right
            transition: 'slide',        // zoom/slide
          },
        },
        // si les cookies sont acceptés (ne pas confondre avec le fait de cliquer sur accepter)
        onAccept: function (cookie) {
          //console.log(cooks);
          //setMapCookie(cookie);
          // document.querySelector('.cookies-button').classList.add('is-visible');
        },
        onFirstAction: function (cookie) {
          window.location.reload();
        },
        onChange: function (cookie) {
          setMapCookie(cookie);
          window.location.reload();
        },

        languages: {
          'fr': {
            consent_modal: {
              title: 'Hello ! voici des <span class="large">Cookies</span>',
              description: 'Ce site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ce dernier ne sera fixé qu\'après accord. <button type="button" data-cc="c-settings" class="cc-link">Choisir mes préférences</button>',
              primary_btn: {
                text: 'Tout accepter',
                role: 'accept_all',             // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Tout rejeter',
                role: 'accept_necessary',        // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Gestion de préférence des cookies',
              save_settings_btn: 'Enregistrer',
              accept_all_btn: 'Tout accepter',
              reject_all_btn: 'Tout rejeter',
              close_btn_label: 'Fermer',
              cookie_table_headers: [
                {col1: 'Nom'},
                {col2: 'Domaine'},
                {col3: 'Expiration'},
                {col4: 'Description'},
              ],
              blocks: [
                {
                  // title: 'Cookie usage 📢',
                  description: 'Sur ce site, nous utilisons des cookies pour mesurer notre audience. Vous pouvez sélectionner ici les services que vous allez autoriser.',
                }, {
                  title: 'Cookies nécessaires au bon fonctionnement du site',
                  description: 'Ces cookies sont indispensables au bon fonctionnement du site internet. Ces cookies sont essentiels au bon fonctionnement du site, vous ne pouvez pas les désactiver.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true,
                  },
                }, {
                  title: 'Publicitaires (ou presque)',
                  description: 'Pour concevoir ce site nous avons fait appel à une société bien de chez nous (à Nîmes pour être précis) et cette société s’appuie pour rendre votre site plus dynamique sur des technologies tierces : YouTube, openmap, etc… Pour profiter de l’ensemble des technologies nous vous invitons à accepter ces cookies.',
                  toggle: {
                    value: 'advertising',
                    enabled: false,
                    readonly: false,
                  },
                }, {
                  title: 'Google Analytics',
                  description: 'Ces cookies permettent d\'analyser les statistiques de consultation de notre site.',
                  toggle: {
                    value: 'analytics',
                    enabled: false,
                    readonly: false,
                  },
                  // cookie_table: [             // list of all expected cookies
                  //     {
                  //         col1: '^_ga',       // match all cookies starting with "_ga"
                  //         col2: 'google.com',
                  //         col3: '2 years',
                  //         col4: 'description ...',
                  //         is_regex: true
                  //     },
                  //     {
                  //         col1: '_gid',
                  //         col2: 'google.com',
                  //         col3: '1 day',
                  //         col4: 'description ...',
                  //     }
                  // ]
                  // }, {
                  //     title: 'Advertisement and Targeting cookies',
                  //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                  //     toggle: {
                  //         value: 'targeting',
                  //         enabled: false,
                  //         readonly: false
                  //     }
                  // }, {
                  //     title: 'More information',
                  //     description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                },
              ],
            },
          },
        },
      });

      //Gestion de la suppression eventuelle du cookie map mais pas du cookie cc_cookie et vice versa
      let ccName = cc.getConfig('cookie_name');
      let ccCookie = Cookies.get(ccName);
      let mapCookie = Cookies.get('map');
      if (mapCookie !== undefined && ccCookie === undefined) {
        Cookies.remove('map', {path: '/'});
        window.location.reload();
      }
      if (ccCookie !== undefined && mapCookie === undefined) {
        let cookie = JSON.parse(ccCookie);
        if (cookie.categories && cookie.categories.includes('advertising')) {
          console.log('yea');
          Cookies.set('map', 1, {expires: 182, path: '/'});
          window.location.reload();
        }
      }


    });

    //Interactive Map
    $(document).on('mouseenter', '.map-city', function () {
      $('#text-' + this.id).addClass('active');
    });

    $(document).on('mouseout', '.map-city', function () {
      $('#text-' + this.id).removeClass('active');
    });


    $(window).on('load', function () {

      // Anchor for Chrome
      var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      if (window.location.hash && isChrome) {
        setTimeout(function () {
          var hash = window.location.hash;
          window.location.hash = "";
          window.location.hash = hash;
        }, 300);
      }

    });
    // ========================================
    // Apidae
    // ========================================


    window.ImageSelector = function(arrayIllustration, priority = 0) {
      // Définir les priorités possibles dans un tableau
      const arrayPriority = ["urlQHD", "url", "urlDiaporama", "urlFiche", "urlListe"];


      // console.log(arrayIllustration);
      // Parcourir les priorités à partir du début du tableau
      for (let i = 0; i < arrayPriority.length; i++) {
        if (arrayPriority[i] in arrayIllustration) {
          return arrayIllustration[arrayPriority[i]];
        }
      }

      // Si aucune images n'est trouvée
      return null;
    }

    //archive Module
    if ($("#map-osm").length) {

      var map = L.map('map-osm').setView([43.835582209397195, 4.362965239094038], 13);
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(map);


      var markers = L.markerClusterGroup();


      markers.addLayer(L.geoJSON([{
        "type": "Point",
        "coordinates": [
          3.608840,
          43.975903
        ]
      }]));

      map.addLayer(markers)
      let currentGeoLoc = [];
      window.addGeoloc = function (arrayGeoLoc) {

        markers.clearLayers()
        currentGeoLoc = arrayGeoLoc;
        markers.addLayer(L.geoJSON(currentGeoLoc));

        map.addLayer(markers)
      }

    }
  },


  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
